import { Icon } from "@components/blocks/icon/Icon";
import { Badge } from "@components/ui/badge";
import { cn } from "@lib/utils";
import { cva } from "class-variance-authority";

interface IPathTypeBadge {
  type: "hiking" | "biking";
  className?: string;
}

export function PathTypeBadge({ type, className }: IPathTypeBadge) {
  const badgeVariants = cva("absolute right-3 top-3 z-40 border-none", {
    variants: {
      type: {
        biking: "border-transparent bg-[#FA6732]",
        hiking: "border-transparent bg-[#F9C026]",
      },
    },
    defaultVariants: {
      type: "biking",
    },
  });

  return (
    <Badge
      className={cn(badgeVariants({ type }), className)}
      variant="outline"
      size="icon"
    >
      <Icon iconName={type} />
    </Badge>
  );
}

import { baseURL } from "@repo/helpers";
import { Language } from "@repo/i18n";

interface RouteBaseParams {
  language?: Language;
  includeBaseUrl?: boolean;
}

interface RouteWebMapParams extends RouteBaseParams {
  pathId?: string;
}

interface RoutePathParams extends RouteBaseParams {
  path: string;
}

interface RouteRoutesOverviewParams extends RouteBaseParams {
  pathId?: string[];
  basePath?: string;
}

interface RouteFacilityParams extends RouteBaseParams {
  id: string;
}

interface RouteContentPageParams extends RouteBaseParams {
  path: string[];
}

interface RouteContentOverviewPageParams extends RouteBaseParams {
  path: string;
}

interface Route<T> {
  route: (params?: T) => string;
}

interface Routes {
  webHome: Route<RouteBaseParams>;
  webMap: Route<RouteWebMapParams>;
  path: Route<RoutePathParams>;
  routesOverview: Route<RouteRoutesOverviewParams>;
  facility: Route<RouteFacilityParams>;
  contentPage: Route<RouteContentPageParams>;
  contentOverviewPage: Route<RouteContentOverviewPageParams>;
}

export const getRoutes = (defaultLanguage?: Language) => {
  const constructRoute = (
    path = "/",
    queryParams: Record<
      string,
      string | undefined | Array<string | undefined>
    > = {},
    params?: RouteBaseParams
  ) => {
    const baseUrl = params?.includeBaseUrl ? baseURL() : "";
    const language = params?.language ?? defaultLanguage;
    const searchParams = new URLSearchParams();
    const urlParts = [];

    Object.entries(queryParams).forEach(([param, values]) => {
      if (Array.isArray(values)) {
        values.forEach(value => {
          if (value !== undefined) {
            searchParams.append(param, value);
          }
        });
      } else if (values !== undefined) {
        searchParams.set(param, values);
      }
    });

    if (params?.includeBaseUrl) urlParts.push(baseUrl);
    if (language) urlParts.push(language);

    if (path.length > 0 && path[0] === "/") {
      path = path.slice(1);
    }

    urlParts.push(path);

    let url = urlParts.join(`/`);

    if (searchParams.size > 0) {
      url = `${url}?${searchParams.toString()}`;
    }

    return params?.includeBaseUrl ? url : `/${url}`;
  };

  const routes: Routes = {
    webHome: {
      route: (params?: RouteBaseParams) => {
        return constructRoute("/", {}, params);
      },
    },
    webMap: {
      route: params => {
        return constructRoute("/kort", { pathId: params?.pathId }, params);
      },
    },
    path: {
      route: params => {
        if (!params?.path) throw Error("Missing required `path` param");

        return constructRoute(`/etaper/${params.path}`, {}, params);
      },
    },
    routesOverview: {
      route: params => {
        return constructRoute(`/etaper`, { pathId: params?.pathId }, params);
      },
    },
    facility: {
      route: params => {
        if (!params?.id) throw Error("Missing required `id` param");

        return constructRoute(`/kort/faciliteter/${params.id}`, {}, params);
      },
    },
    contentPage: {
      route: params => {
        if (!params?.path) throw Error("Missing required `path` param");

        return constructRoute(`/${params.path.join(`/`)}`, {}, params);
      },
    },
    contentOverviewPage: {
      route: params => {
        if (!params?.path) throw Error("Missing required `path` param");

        return constructRoute(`/${params.path}`, {}, params);
      },
    },
  };

  return routes;
};
